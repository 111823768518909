@import "../../styles/base/variables";

.snapshot {
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
  background: $color-gray-lightest;
  padding-bottom: 50px;

  &--xl {
    max-width: 1600px;
  }

  &__wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $site-header-z-index + 2;
    padding-top: 50px;
  }

  &__content {
    padding: 20px;

    &--disable {
      padding: 0;
    }
  }

  &__actions {
    height: 50px;
    background: $gradient-black;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.9), 0 0px 2px rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 1200px;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;

    &--xl {
      max-width: 1600px;
    }

    & > div {
      margin-right: 10px;
    }

    & > button,
    & > a {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    &-title {
      flex: 1;
      font-size: 24px;
      font-weight: 400;
      color: white;
    }
  }

  &__card {
    background: white;
    border-radius: $border-radius;
    padding: 20px;
    box-shadow: 0 2px 0 $color-primary, 0 0 6px rgba(0, 0, 0, 0.1);
  }

  &__background {
    // background: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
