@mixin reset-list() {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin for-phone-only {
  @media (max-width: 480px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 992px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-phone-portrait-down {
  @media (max-width: 580px) {
    @content;
  }
}

@mixin for-tablet-portrait-down {
  @media (max-width: 768px) {
    @content;
  }
}
@mixin for-tablet-landscape-down {
  @media (max-width: 992px) {
    @content;
  }
}
@mixin for-desktop-down {
  @media (max-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-down {
  @media (max-width: 1800px) {
    @content;
  }
}
@mixin for-desktop-height-down {
  @media (max-height: 800px) {
    @content;
  }
}
@mixin for-desktop-height-tall-down {
  @media (max-height: 1200px) {
    @content;
  }
}

@mixin font-title-small {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.85rem;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 10px;
}
