@import "../../../styles/base/vars.css";

.buttonWrapper {
  display: inline-block;
}

.button {
  background-color: var(--color-gray-lightest);
  color: #333;
  font-size: 16px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
  transition: background-color 0.25s;
  border-radius: 2px;
  text-align: center;
}

.button svg {
  fill: #333;
  height: 18px;
  width: 18px;
  margin: auto;
}

.button:hover,
.button:focus {
  background-color: var(--color-gray-lighter);
  outline: 0; /* reset for :focus */
}

.active {
  color: var(--color-orange);
}

.active svg {
  fill: var(--color-orange);
}
