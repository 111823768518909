@import "../../styles/base/variables";

.table {
  width: 100%;
  height: 100%;
  flex: 1;
  position: relative;
  overflow: hidden;
  display: block !important; // Fix for update to MUI 5

  &__wrapper {
    height: calc(100% - #{$table-pagination-height} - 32px);

    &--nopagination {
      height: 100%;
    }
  }

  &__row {
    background-color: white;
    transition: background-color 0.25s;

    &:nth-child(odd) {
      background-color: $color-gray-lightest;
    }

    &:hover {
      background-color: $color-gray-light !important;
    }

    &-heading {
      font-weight: 600;
      font-size: 1.125rem;
      color: $color-gray-dark;
    }

    &-subheading {
      font-weight: 600;
      font-size: 1rem;
      color: $color-secondary;
    }
  }

  &__pagination {
    background: $color-gray-lightest;
    border-top: 1px solid $color-gray-lighter;
  }

  & .visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 20px;
    width: 1px;
  }

  &__is-new {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 1rem;
    background: $color-gray;

    &--active {
      background: $color-primary;
      border: 1px solid $color-primary;
    }
  }
}

.MuiTableBody-root {
  & .MuiTableRow-root {
    background-color: white;
    transition: background-color 0.25s;

    &:nth-child(odd) {
      background-color: $color-gray-lightest;
    }

    &:hover {
      background-color: $color-gray-light !important;
    }
  }

  & .MuiButtonBase-root {
    color: rgba(0, 0, 0, 0.54);
  }
}
