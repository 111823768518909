@import "styles/base/mixins";

.time-clock{
  .bulk-edit{
    padding: 0.5rem;

    > .heading{
      display: flex;

      > .actions{
        flex: 1 0 auto;
        display: flex;
        justify-content: flex-end;

        .MuiIconButton-root{
          margin-left: 1rem;
          margin-right: 1rem;

          .label{ width: 150%; }

          &.shift-start, &.shift-start:hover{
            background-color: var(--color-green);

            svg.pencil{
              fill: var(--color-green);
            }
          }

          &.break-start, &.break-start:hover{
            background-color: var(--color-orange);
          }

          &.break-end, &.break-end:hover{
            background-color: var(--color-green);
          }

          &.shift-end, &.shift-end:hover,
          &.delete, &.delete:hover{
            background-color: var(--color-red);
          }

          &.complete, &.complete:hover{
            background-color: var(--color-yellow);
          }

          &:hover{
            opacity:  0.8;
          }

          &.disabled, &.disabled:hover{
            background-color: var(--color-gray);
            opacity:  0.6;
            cursor: default;
            svg.pencil{ fill: var(--color-gray); }
          }
        }
      }
    }

    .filters {
      .MuiGrid-item{
        padding-top: 0;
      }

      .MuiTextField-root{
        margin-bottom: 0;
      }
    }

    .edit-item{
      display: flex;
      margin: 0 0 0.5rem;
      padding: 1rem 0.5rem 0.5rem 0;

      & + .edit-item{ margin: 0.5rem 0; }

      &:hover{
        background-color: var(--color-gray-light);
      }
      &.checked{
        background-color: var(--color-blue-light-fade);
      }

      .time-card-line-item{
        flex: 1 0 auto;
        padding-top: 0;
      }
    }
  }

  .MuiIconButton-root.bulk-edit-icon{
    svg { color: var(--color-white); }

    &.shift-start, &.shift-start:hover{
      svg{
        &.clock{ margin: 0 0 -0.25rem -0.25rem; }

        &.pencil{
          fill: var(--color-black);
          position: absolute;
          width: 41%;
          height: 41%;
          top: 0.4rem;
          right: 0.3rem;
        }
      }
    }

    &.shift-end, &.break-end{
      svg{
        width: 50%; height: 50%;
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0.25rem;

        &.walk{
          left: auto;
          right: 0;
        }
      }
    }

    &.detail{
      color: var(--color-gray-darkest);
      width: 2rem;
      height: 2.25rem;
      padding: 4px;
      cursor: pointer;

      svg{
        position: absolute;

        &.time-card{
          width: 62%;
          height: 62%;
          left: 1px;
        }

        &.open{
          width: 55%;
          height: 55%;
          top: 0;
          right: 0;
        }
      }
    }
  }
}
