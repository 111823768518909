@import "../../styles/base/variables";
@import "../../styles/base/mixins";

.page-header {
  display: flex;
  align-items: center;
  background: var(--color-gray-darkest);
  color: var(--color-gray-lightest);
  height: $page-header-height;
  justify-content: space-between;
  padding: 0 20px;
  box-shadow: var(--shadow-inner);

  &__wrapper {
    width: inherit;
    background: white;
    height: $page-header-height;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 0px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: $site-header-z-index - 1;
  }

  &__nav {
    @include reset-list();

    &-wrapper {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      @include for-tablet-landscape-down() {
        position: static;
        left: auto;
        transform: none;
      }

    }

    &-item {
      display: inline-block;
      margin: 0 15px;

      a,
      div {
        display: flex;
        align-items: center;
        height: $page-header-height;
        text-decoration: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0);
        color: lighten($color-gray-light, 20%);
        font-weight: 600;
        transition: color 0.25s, border 0.25s;
        cursor: pointer;

        &:hover {
          color: $color-gray;
          border-bottom: 2px solid $color-primary;
        }

        &.is-active {
          color: $color-gray;
          border-bottom: 2px solid $color-primary;
        }
      }
    }
  }

  &__title {
    &-wrapper {
      display: flex;
      align-items: center;
    }
  }

  &__icon {
    margin-right: 10px;
  }
}
