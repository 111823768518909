/* Need the wrapper to override the plugin's styles */
.image-zoom-wrapper {
	position: relative;
	width: 100%;
	height: 100%;

	.content-wrapper .react-transform-wrapper{
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: var(--color-gray-light);

		/* Fixes a Safari bug where it can't handle width: fit-content */
		.react-transform-component{
			width: auto;
			height: auto;
			display: inline-block;
		}
	}
}
