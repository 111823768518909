@import "styles/base/mixins";

.time-clock{
  &.cards{
		> .heading{
			display: flex;

			.button{
				display: block;
				font-weight: bold;
				cursor: pointer;
				padding: 0.25rem;
				border-radius: var(--border-radius);

				svg{
					width: 28px;
					height: auto;
					color: var(--color-gray-dark);

					&.disabled{
						color: var(--color-gray);
						cursor: default;
					}
				}

				&.active, &:hover{
					background-color: var(--color-gray-light);

					&.disabled{
						background-color: transparent;
						cursor: default;
					}
				}

				& + .button{ margin-left: 0.5rem; }
			}

			.toggles{
				display: flex;
				align-items: center;
				padding-left: 1rem;
			}

			.filters{
				flex: 1 0 auto;
			}

			.labor-actions{
				display: flex;
				align-items: center;
				padding-right: 1rem;

				.button{
					// width: 40px;
				}
			}

			.right > div{ flex: 0 1 auto; }
		}

		.overview{
			display: flex;
			flex-wrap: wrap;
			padding: 0 1rem;
			align-items: stretch;

			.user{
				padding: 0 1rem 2rem;
				flex: 0 1 33.333%;

				@include for-desktop-down() {
					flex: 0 1 50%;
				}

				@include for-tablet-portrait-down() {
					flex: 0 1 100%;
				}

				.mui-card{
					padding: 0.7rem;
					height: 100%;
					border: 1px solid var(--color-gray);
				}
			}
		}

		&.user .content{
			padding-top: 0.25rem;
		}
	}

	&.card-user{
		position: relative;

		&.detail{
			padding: 1rem 0.5rem;
		}

		.user-heading{
			display: flex;
			// flex-wrap: wrap;
			align-content: flex-start;
			justify-content: space-between;
			padding: 0.35rem;
			border-radius: var(--border-radius-medium);
			background-color: var(--color-blue-light-fade);
			border: 1px solid var(--color-gray);

			// &.summary{
			// 	cursor: pointer;

			// 	&:hover{
			// 		background-color: var(--color-gray-light);
			// 	}
			// }

			.avatar{
				padding: 0 0.35rem 0 0;
			}

			.info{
				padding: 0 0 0 0.35rem;
				flex: 1 0 65%;

				.info-heading{
					display: flex;

					h5{
						flex: 1 0 auto;
					}
				}

				.sub-info{
					display: flex;
					justify-content: flex-start;

					.account-type{
						&:not(.has-totals){
							display: flex;
							align-items: center;
						}

						.text{
							margin-left: 0.5rem;
						}
					}

					.totals{
						flex: 1 0 auto;
						padding-left: 1rem;
						font-size: 0.8rem;
					}
				 }
			}

			.manual-entry-add,
			.detail-open,
			.detail-close{
				align-self: flex-start;
			}

			.manual-entry-add,
			.detail-open{
				cursor: pointer;
				color: var(--color-gray-dark);

				button{
					padding: 5px;
				}

				.icon{ margin-right: 0; }
			}
			&.summary .manual-entry-add button{
				padding: 2px;
				width: 20px;
				height: 20px;
			}
		}

		.empty-message{
			color: var(--color-gray-dark);
			background-color: var(--color-gray-lighter);
			margin: 1rem 0;
			border: 1px solid var(--color-gray-middle);
			border-radius: var(--border-radius);
			padding: 0.5rem;

			.icon{ width: 30px; height: auto; }
		}

		// .cards{
		// 	// flex: 1 0 100%;
		// }

		.more-cards{
			text-align: right;
			padding: 0.25rem 0.25rem 0;

			.text{
				display: inline-block;
				padding: 0.25rem 0 0.25rem 0.5rem;
				border-radius: var(--border-radius);
				cursor: pointer;

				&:hover{
					background-color: var(--color-gray-light);
				}
			}
		}

		.card-qtys{
			display: flex;
			align-items: stretch;

			.qty-line{
				flex: 1 1 auto;
				padding: 0.25rem 0.25rem 0.25rem 1rem;
				margin: 0.25rem 0;
				background-color: var(--color-white);
				border: solid 1px var(--color-gray);
				border-radius: var(--border-radius);

				& + .qty-line{
					margin-left: 0.5rem;
				}

				// &:last-child{
				// 	margin-bottom: 0;
				// }

				&.none{
					background-color: var(--color-red-fade);
				}

				&.open{
					background-color: var(--color-yellow-fade);
				}

				// .icon{
				// 	display: inline-block;
				// 	width: 1.5em;
				// 	height: auto;
				// }
			}
		}
	}


	&.card-user, .time-card-line-item {
		.icon{
			display: inline-block;
			width: 20px;
			height: 20px;
			margin-right: 0.5rem;
			vertical-align: middle;

			&.small{
				width: 17px;
				height: 17px;
				margin-right: 0.25rem;
			}
		}
	}

	.time-card-line-item{
		padding: 1rem 0 0;

		&:not(.click-override):hover > .card{
			background-color: var(--color-blue-light-fade);

			&.manual{
				background-color: var(--color-blue-lightest);
			}
		}

		> .card{
			display: flex;
			border: 1px solid var(--color-gray-medium);
			padding: 0.75rem;
			border-radius: var(--border-radius);
			cursor: pointer;

			&.manual{
				opacity: 0.7;
				background-color: var(--color-gray-lighter);
			}

			.avatar{
				padding-right: 0.5rem;
			}

			.wrapper{
				flex: 1 0 auto;
				max-width: 100%;
			}

			.user-info{
				display: flex;
				padding-bottom: 0.125rem;
				border-bottom: 1px solid var(--color-gray-medium);
				margin-bottom: 0.125rem;

				.MuiTypography-subtitle2{
					align-self: flex-end;
					flex: 1 0 auto;
					padding-left: 0.5rem;
				}
			}

			.heading{
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-bottom: 0.125rem;

				& > * {
					display: inline-flex;
					align-items: center;
				}

				.status, .manual-info{
					display: inline-flex;
					width: auto;
					height: auto;
					padding: 0 1rem;
					margin-right: 0;
					flex: 1 0 auto;

					&.right{ justify-content: right; }

					svg{ margin-right: 0.5rem; }

					&.manual-info .info{
						text-align: left;
						font-size: 0.85rem;
					}
				}

				.icon.delete{
					margin: 0 0 0 1rem;
					color: var(--color-red);
				}

				.icon.disabled{
					color: var(--color-gray-medium);
				}
			}

			.labor-info{
				display: flex;
				padding: 0.5rem 0 0;
				justify-content: space-between;
				flex-wrap: wrap;

				> * {
					margin-bottom: 0.5rem;

					&:first-child{ margin-right: 0.25rem; }
				}
			}

			.times{
				display: flex;
				justify-content: space-between;
				background-color: var(--color-gray-light);
				padding: 0.5rem;
			}

			.time-hour-info{ margin-top: 0; }

			.hours-division{
				display: flex;
				margin-top: 0.25rem;

				.hours{
					flex: 0 1 40%;
					padding-right: 1rem;

					.time-hour-info{
						width: 100%;
						margin-bottom: 0;
					}
				}

				.division{
					display: flex;
				}

				&.detail{
					.hours{
						flex: 1 0 60%;
						display: flex;
						padding: 0;
					}

					.division{
						width: 100%;
						padding: 0.25rem 0.25rem 0.25rem 0.75rem;
						border: 1px solid var(--color-gray);
						border-left: none;
						background-color: var(--color-blue-light-fade);
					}
				}
			}
		}

		&.click-override > .card{
			cursor: default;
		}
	}

	.admin-buttons{
		> * {
			vertical-align: middle;
			padding: 2px;
			margin: 0 0.25rem !important;
		}

		&.small{
			padding: 1px;

			.edit-icon{ width: 17px; padding: 0; }

			.MuiIconButton-root{ padding: 0; }
			.MuiSvgIcon-root{ font-size: 17px; }
		}
	}

	.manual-entry{
		display: flex;
		flex-direction: column;
		align-items: center;

		.admin-buttons{
			position: absolute;
			right: 0;
		}

		&.buttons-only {
			flex-direction: row;
			justify-content: flex-end;

			.admin-buttons{ position: relative; }
		}

		.manual-info .info{ text-align: center; }
	}

	.manual-info{
		display: flex;
		justify-content: flex-end;
		text-align: left;

		.info span{ display: block; }
	}
}
