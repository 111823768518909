.map-street-view{
  width: 100%;
  position: relative;

  > .pano{
    height: 100%;
  }

  &.mobile > .pano{
    display: none;
  }

  > .maximize{
    position: absolute;
    right: 0;
    top: 0;
    padding: 0.5rem;
    color: var(--color-white);
    z-index: 3;
    cursor: pointer;

    .icon{
      width: 35px;
      height: 35px;
      filter: drop-shadow( 1px 1px 2px rgba(0, 0, 0, .65));
    }

    .photo{
      border: 2px solid var(--color-white);
      box-shadow: var(--drop-shadow-sharp);
      overflow: hidden;
      height: 80px;
      width: 100px;

      img{
        width: 125px;
        height: 100px;
        max-width: none;
        margin-top: -10px;
        margin-left: -12px;
      }
    }
  }
}
