@import "~@syncfusion/ej2-base/styles/material.css";
@import "~@syncfusion/ej2-buttons/styles/material.css";
@import "~@syncfusion/ej2-popups/styles/material.css";
@import "~@syncfusion/ej2-splitbuttons/styles/material.css";
@import "~@syncfusion/ej2-inputs/styles/material.css";
@import "~@syncfusion/ej2-navigations/styles/material.css";
@import "~@syncfusion/ej2-image-editor/styles/material.css";
@import "styles/base/mixins";

.image-interaction-wrapper{
	display: flex;

	.image-view-wrapper{
		position: relative;
		height: 100%;

		> .editor-buttons{
			position: absolute;
			top: 0;
			right: 0;
			margin: 0.5rem;

			@include for-tablet-portrait-down() {
				right: 32px;
			}

			.icon-circle-button{
				margin-left: 0.5rem;

				&.original-image{
					padding: 0.45rem 0.3rem 0.35rem 0.55rem;
				}
			}
		}
	}

	.editor-wrapper{
		flex: 0 1 75%;
		position: relative;

		// .control-pane-wrapper{
		// 	display: flex;
		// 	flex-direction: column;
		// 	height: 100%;
		// }
	}

	.messages-wrapper{
		flex: 0 1 25%;
	}

  @include for-tablet-portrait-down() {
		display: block;

		.editor-wrapper {
			height: calc(0.55 * ((var(--vh, 1vh) * 100) - var(--page-header-height)));
			padding: 0.25em;
		/* Help the Maximizer's animation */
			position: static;
			top: var(--page-header-height);
			&.minimize-start{
				transition: top 0s;
				top: calc(0px - var(--page-header-height));
			}

			.image-zoom-wrapper .content-wrapper{
				position: relative;
				width: 100%;
				height: 100%;
			}
		}

		.messages-wrapper{
			height: calc(0.45 * ((var(--vh, 1vh) * 100) - var(--page-header-height)));
			padding: 0.5em 0.25em 0.25em;

			.DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content{
				min-height: 0;
			}
			.public-DraftStyleDefault-block{
				margin: 0;
			}
		}

		@at-root (with: rule) {
			@include for-tablet-portrait-down() {
				.lightroom{
					.editor-wrapper{
						height: calc(0.55 * ((var(--vh, 1vh) * 100) - var(--page-header-height)) - 1em);
					}

					.messages-wrapper{
						height: calc(0.45 * ((var(--vh, 1vh) * 100) - var(--page-header-height)) - 1em);
					}
				}
			}
		}

		.editor-wrapper .image-zoom-wrapper .content-wrapper .react-transform-wrapper,
		.messages-wrapper > .messages-frame{
			border-radius: 1em;
			background-color: var(--color-gray-lightest);
			box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
		}
	}
}


.e-image-editor {
	margin: 0 auto;

	.e-canvas-wrapper{
		background-color: var(--color-gray-light);
	}
}

.e-img-editor-sample {
	height: 80vh;
	width: 100%;
}

@media only screen and (max-width: 700px) {
	.e-img-editor-sample {
		height: 70vh;
		width: 100%;
	}
}

.control-wrapper {
	height: 100%;
}
.control-pane-wrapper{
	background-color: var(--color-gray-lightest);
}

.e-toolbar-item.e-image-upload{ display: none; }
