@import "../../../styles/base/vars.css";

.toolbar {
  border-top: 1px solid var(--color-gray-medium);
  background: var(--color-gray-lightest);
  z-index: 2;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  padding: 2px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
