@import "../../styles/base/variables";

.paper {
  background: white;
  box-shadow: 0 2px 0 $color-gray, 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: $border-radius;
  margin: 20px;
  overflow: hidden;
  outline: none !important;

  &--square {
    border-radius: none;
  }

  &--square-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &--no-gutter {
    margin: 0;
  }

  &--outlined {
    border: 1px solid $color-gray;
  }

  &--primary {
    box-shadow: 0 2px 0 $color-primary, 0 0 6px rgba(0, 0, 0, 0.1);
  }
}
