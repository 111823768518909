@import "styles/base/mixins";

// .camera-video{
// 	width: 300;
// 	height: 200;
// }
.camera-dialog{

	.MuiDialogContent-root{
		padding-top: 1rem;
	}

	#camera-dialog-dialog-title{
		display: flex;
		justify-content: space-between;
		padding-bottom: 0;

		.switch-button{
			cursor: pointer;
		}
	}

	.image-wrapper{
		position: relative;

		.video{
			position: relative;
			z-index: 1;
			border: 3px solid var(--color-red);
			border-radius: var(--border-radius-medium);
			box-shadow: var(--drop-shadow-sharp);
		}

		.loading{
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 0;
		}

		.canvas{
			display: none;
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			border: 3px solid var(--color-black);
			border-radius: var(--border-radius-medium);
			box-shadow: var(--drop-shadow-sharp);
		}
	}

	.button-wrapper{
		display: flex;
	  justify-content: space-around;
		padding-top: 1rem;

		.switch-button{
			width: 40px;
			height: 40px;
			cursor: pointer;
		}
		.snapshot-button{
			border-radius: 50%;
			background-color: var(--color-red);
			width: 35px;
			min-width: 35px;
			height: 35px;
			cursor: pointer;
			padding: 0;
			border: 2px solid var(--color-white);
			box-shadow: 0 0 1px 1px var(--color-black);
		}

		.snapshot-retake,
		.snapshot-submit{
			.icon{
				display: inline-block;
				margin-right: 0.25rem;
			}
		}
	}
}
