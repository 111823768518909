@import "styles/base/mixins";

.time-clock{
	&.main{
		flex: 1 0 100%;
		padding: 0.5rem 1rem;
		width: 620px;
		max-width: 100%;
		margin: 0 auto;
	}

	&.dialog {
		&:not(.small){
			width: 620px;
			max-width: 100%;
			margin: auto;
		}

		.time-clock{
			padding: 0;

			.MuiCard-root{
				border-radius: 0;
				border: none;
				background: transparent;
				box-shadow: none;
			}
		}
	}


	&.heading{
		.content-wrapper{
			flex: 1 0 auto;

			.content{
				flex: 1 0 auto;
				display: flex;
			}

			.buttons{
				flex: 1 0 auto;
				text-align: right;

				.icon{
					display: inline-block;
					cursor: pointer;
					width: 22px;
					height: auto;

					&.close{
						width: 35px;
						margin-left: 0.75rem;
					}

					@media print{
						display: none;
					}
				}
			}
		}
	}

	.read-only-heading{
		padding: 0.25rem;
		text-align: center;
		font-size: 1.15rem;
		background-color: var(--color-yellow);
	}

	.customer-header{
		display: flex;
		justify-content: space-between;

		.info{
			margin-bottom: 0.5rem;
			font-size: 1.15rem;
		}

		.expander{
			cursor: pointer;

			.icon{
				width: 20px;
				height: auto;
			}
		}
	}

	&.main .label{
		display: inline-block;
		font-style: italic;
		font-size: 1.15em;
		margin-right: 0.25em;
	}

	.map-collapser{
		position: relative;
		z-index: 0;

		& + *{
			position: relative;
			z-index: 1;
		}
	}

	.change-time-card{
		text-align: right;

		.new-time-card{
			display: inline-block;
			padding: 0.25rem 0.5rem;
			border: 1px solid var(--color-gray);
			border-radius: var(--border-radius);
			margin: -0.5rem 0 0.5rem;
			cursor: pointer;

			> svg{ margin: 0 0.5rem 0 0; }
		}
	}

	.submitting{
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100%;
		background-color: var(--color-gray-alpha-low);
		z-index: 2;

		.loader{
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto;
		}
	}

	.change-time-card-dialog{
		text-align: center;

		.or{
			margin: 0.75rem 0;
		}

		.select-helper{
			font-size: 1rem;
			text-align: center;
			font-style: italic;
		}

		.buttons{
			display: flex;
			justify-content: space-between;
		}
	}

	.user-select{
		padding: 0.5rem;
		border-bottom: 1px solid var(--color-gray);
		background: var(--gradient-black);
		color: var(--color-white);

		.temp-user-name{
			display: flex;

			> .name{
				flex: 1 0 50%;

				& + .name{
					padding-left: 1rem;
				}
			}
		}

		.login-button{
			text-align: center;

			.or{ margin-bottom: 0.75rem; }
		}
		.heading{
			display: flex;
			justify-content: space-between;
			align-items: center;

			.clock-icon{
				padding-right: 0.5rem;
			}

			.user-name{
				flex: 1 1 auto;
			}

			.set-email{
				cursor: pointer;
			}

			.change-user{
				cursor: pointer;

				> svg{ margin-right: 0.25rem; }
			}
		}

		.user-buttons{
			text-align: right;
		}

		.change-user-wrapper .change-user{
			text-align: center;

			.cancel{
				margin-right: 1rem;
			}
		}
	}

	.div-info{
		> .title{
			display: flex;
			padding: 0.5rem;

			.division-type-icon{
				margin-right: 0.5rem;
			}
		}

		.customer-header{
			padding: 0 1rem;
		}
	}

	.service-type, .service-activity, .service-travel{
		padding: 0.5rem 0.5rem 0.2rem;
		border-top: 1px solid var(--color-gray);

		> .edit-icon{ margin-left: 0; }

		&.inline-field{
			display: flex;
			align-items: flex-start;

			.edit-icon{ padding-top: 0.5rem; }

			.wrapper{ flex: 1 0 auto; }
		}
	}
	.service-type, .service-travel{ background-color: var(--color-gray-lighter); }
	.dialog-content {
		.service-type, .service-activity{
			&.inline-field{
				display: block;
				padding: 0;
				background: transparent;
				border-top: none;

				.edit-icon{ display: none;}
			}
		}
	}

	.edit-icon{
		display: inline-block;
		width: 17px;
		height: auto;
		padding: 2px;
		margin: 0 0.5rem;
		cursor: pointer;

		&.medium{
			width: 20px;
		}

		&.large{
			width: 30px;
		}
	}

	.timer{
		padding: 0.5rem 0;

		.buttons-wrapper{
			display: flex;
			justify-content: space-between;
			padding-bottom: 1rem;

			.clock-icon{
				align-self: flex-start;
				padding-left: 0.5rem;
			}

			.buttons{
				text-align: center;
				display: flex;
				justify-content: space-around;
				flex: 1 0 auto;

				.large-round-button{
					background-color: var(--color-green);

					&.break-start, &.break-end{
						background-color: var(--color-orange);
						color: var(--color-white);
					}

					&.clock-out{
						background-color: var(--color-red);
						color: var(--color-white);
					}
				}
			}
		}

		.time-info-header{
			font-size: 1.125rem;
			display: flex;
			justify-content: space-between;

			.update-times{
				cursor: pointer;
				padding-right: 0.5rem;

				.edit-icon{ margin-right: 0.25rem; }

				.text{
					color: var(--color-secondary);
					font-size: 0.8rem;
				}
			}
		}
	}

	.time-info{
		display: flex;
		padding-top: 0.25rem;
		align-items: flex-start;
		padding-right: 0.5rem;

		.edit-icon{
			padding-top: 0;
		}

		.list{
			flex: 1 0 auto;
			border: 1px solid var(--color-gray);
			border-radius: var(--border-radius);

			.item{
				display: flex;
				justify-content: space-between;
				padding: 0.125rem 0.5rem;
				max-width: 79vw;

				.location{ padding: 0.125rem 0 0.125rem 0.5rem;}

				.snapshot-thumb-wrapper{
					position: relative;

					.snapshot-thumb{
						width: 75px;
						height: auto;
					}

					.icon-circle-button{
						display: none;
						position: absolute;
						top: 0.125rem;
						right: 0.125rem;
						padding: 0.25rem;
						height: 1.75em;
						width: 1.75em;
						justify-content: center;
						align-content: center;
					}

					&.expandable{
						cursor: pointer;

						&:hover .icon-circle-button{
							display: flex;
						}
					}
				}

				&:nth-child(odd){
					background-color: var(--color-gray-light);
				}
			}
		}
	}

	&.dialog .service-breaks{
		padding-left: 1rem;

		.break-wrapper{
			.break-times{
				display: flex;
				// padding-left: 0.5rem;
				justify-content: space-between;
				align-items: flex-start;

				> *{
					padding: 0 0.25rem;
					margin-bottom: 0;
				}
			}
		}
	}

	.time-hour-info{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0.25rem 0 0.5rem;

		> * {
			flex: 1 1 auto;
			border: 1px solid var(--color-gray);
			background-color: var(--color-blue-light-fade);
			text-align: center;
			padding: 0.25rem;
		}

		&.use-heading > *:first-child{
			flex: 1 1 100%;
		}
	}

	.signature-message{
		flex: 1 0 auto;
		text-align: center;
		padding: 0 0.5rem 0.75rem;

		// .MuiTypography-body{
		// 	font-size: 0.95rem;
		// }
	}

	.signature-display{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		padding: 0.25rem 2rem 0.75rem;

		.image{
			flex: 1 1 auto;

			img{
				border: 2px solid var(--color-gray);
			}
		}

		.name, .date{
			flex: 1 1 50%;
			padding: 0.25rem 0.5rem;
			border: 2px solid var(--color-gray);
			border-top: none;
		}
	}

	.signature-body{
		margin: 0.75rem 1vw;
		padding: 0.5rem 1rem;
		border: 1px solid var(--color-gray);

		.MuiTypography-body{
			display: block;
			text-indent: 1rem;
		}

		.signature-form-field-wrapper{
			text-align: right;
			padding-bottom: 0.5rem;

			 > *{
				margin: 0 !important;
			 }
		}

		.signature-name-date{
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;

			> *{
				flex: 0 1 50%;
				padding: 0 0.5rem;
			}

			.button-loader{ text-align: right; }
		}
	}

	.service-log{
		display: flex;
		border-top: 1px solid var(--color-gray);
		padding: 0.5rem 0.5rem 0.5rem 0;

		.icon{
		}

		.list{
			flex: 1 1 auto;
			border: 1px solid var(--color-gray);
			border-radius: var(--border-radius);

			.log-entry{
				display: flex;
				flex-wrap: wrap;
				padding: 0.25rem 0.5rem;

				&:nth-child(even){
					background-color: var(--color-gray-light);
				}

				*{ flex: 1 0 50%; }

				.datetime{
					// text-align: right;
				}

				.message, .user{
					flex: 1 0 100%;
					text-indent: 1rem;
				}
			}
		}
	}

	&.dialog{
		padding: 0;

		.dialog-title{
			display: flex;
			align-items: flex-start;
			padding: 1rem 0.4rem;
		}
	}

	&__qr-code{
		padding: 1.5rem 0 0.5rem 0;
		text-align: center;

		.heading{
			padding: 0.5rem 0;
			text-align: center;

			.alias-link{
				font-size: 1.25em;
			}
		}

		.card{
			display: inline-block;

			.code{
				padding: 1rem;
			}
		}
	}
}
