.editor {
  box-sizing: border-box;
  cursor: text;
  padding: 0.5rem 1rem;
  overflow: auto;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 2rem;
  max-height: 155px;
}

.headlineButtonWrapper {
  display: inline-block;
}

.headlineButton {
  background: #fbfbfb;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
}

.headlineButton:hover,
.headlineButton:focus {
  background: #f3f3f3;
}
