.uppy-error-message-wrapper{
  display: flex;
  flex: 1;

  .message{
    background-color: var(--color-red-fade);
    padding: 0.5rem 0.75rem;
    margin: 0 0.5rem;
    display: inline-block;
    border-radius: var(--border-radius);
    font-size: 0.9em;
  }
}
