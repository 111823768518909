@import "../../styles/base/variables";

.comp-tracker {
  width: 150px;
  height: 1rem;
  background: $color-gray;
  border-radius: $border-radius;
  display: flex;
  overflow: hidden;
  position: relative;

  &__item {
    flex: 1;
    background: $color-gray;
    border-right: 2px solid white;

    &:last-child {
      border-right: none;
    }

    &--validated {
      background: $color-green;
    }

    &--invalid {
      background: $color-gray;
    }
  }

  &__bar {
    height: 100%;
    position: absolute;
  }

  &__label {
    position: absolute;
    z-index: 1;
    right: 0;
    background: #{$color-black}80;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 5px;
    color: white;
    font-size: 0.75rem;
  }
}

.round-progress {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background: $color-gray-lighter;
}
